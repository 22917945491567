import styled from "livemap-ui";
import Icon from "@mdi/react";
import { mdiMapMarker, mdiDomain } from "@mdi/js";
import { CompanyInformation } from "../types/company-information";

import { fitCompanyInView } from "../core/fit-reports-in-view";

interface CompanyInformationProps {
  map: maplibregl.Map;
  companyInformation: CompanyInformation;
  coordinates: Number[];
}

export function CompanyInformationCard({
  map,
  companyInformation,
  coordinates,
}: CompanyInformationProps) {
  return (
    <Container onClick={() => fitCompanyInView(map, coordinates)}>
      <DataPoint>
        <Name>{companyInformation.name}</Name>
      </DataPoint>

      {companyInformation.companyDescription && (
        <DataPoint>{companyInformation.companyDescription}</DataPoint>
      )}
      <DataPoint>
        <Icon path={mdiMapMarker} size="22px" color="rgb(24, 122, 220)" />

        <Text>{`${companyInformation.street}, ${companyInformation.postCode}, ${companyInformation.city}`}</Text>
      </DataPoint>
      <DataPoint>
        <Icon path={mdiDomain} size="22px" color="rgb(24, 122, 220)" />
        <Text>Org.nr {companyInformation.companyRegistrationNumber}</Text>
      </DataPoint>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 0;
  overflow-y: auto;
  padding: 24px;

  @media (max-width: 500px) {
    width: 100vw;
  }

  &:hover {
    background: rgb(248, 244, 244);
  }
`;

const Name = styled.div`
  font-size: 22px;
  padding: 0 0 12px 0;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: rgb(6, 15, 25);
`;

const DataPoint = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 0;
  font-weight: 500;
  color: rgba(6, 15, 25, 0.6);
`;

const Text = styled.div`
  padding-left: 8px;
`;
