import {
  mdiEarth,
  mdiEmail,
  mdiHomeCircleOutline,
  mdiMapMarker,
  mdiDotsVertical,
} from "@mdi/js";
import Icon from "@mdi/react";
import maplibregl from "maplibre-gl";
import styled from "livemap-ui";
import { setSearchParams } from "../core/url";

interface ContactButtonProps {
  disabled: boolean;
}

export function CompanyInformationCondensedCard(props: {
  companyInformation: any;
  map: maplibregl.Map;
  setCompanyInformation: any;
  setCompanyInformationSmall: any;
  setSearchedCompanies: any;
}) {
  const flyToCompany = (map: any, point: any) => {
    setSearchParams("search", [props.companyInformation.properties.name]);

    props.setCompanyInformation(props.companyInformation);
    props.setCompanyInformationSmall(null);

    map.flyTo({
      center: point,
      zoom: 12,
    });
  };

  let handleClick = (e: any) => {
    if (e.target instanceof HTMLAnchorElement) return;

    props.setSearchedCompanies(props.companyInformation.properties.name);
    flyToCompany(props.map, props.companyInformation.geometry.coordinates);
  };

  return (
    <div>
      <Card className="card" onClick={(e) => handleClick(e)}>
        {props.companyInformation.properties.premium && (
          <DataPoint>
            <Logo
              src={`data/logos/${props.companyInformation.properties.companyRegistrationNumber}.png`}
            />
          </DataPoint>
        )}
        <DataPoint>
          <Name>{props.companyInformation.properties.name}</Name>
          {props.companyInformation.properties.premium && (
            <Icon path={mdiHomeCircleOutline} size={1.2} color="gold" />
          )}
        </DataPoint>
        <DataPoint>
          <Icon path={mdiMapMarker} size={0.8} color="rgb(24, 122, 220)" />
          <Street>{`${props.companyInformation.properties.street}, ${props.companyInformation.properties.postCode}, ${props.companyInformation.properties.city}`}</Street>
        </DataPoint>

        {props.companyInformation.properties.premium && (
          <DataPointContact>
            <ContactButton
              href={props.companyInformation.properties.site}
              target="_blank"
              disabled={!props.companyInformation.properties.site}
            >
              Hemsida <Icon path={mdiEarth} size="16px" />
            </ContactButton>
            <ContactButton
              href={`mailto:${props.companyInformation.properties.email}`}
              target="_blank"
              disabled={!props.companyInformation.properties.email}
            >
              Email <Icon path={mdiEmail} size="16px" />
            </ContactButton>
            <MoreInfoButton>
              Mer info
              <Icon path={mdiDotsVertical} size="22px" />
            </MoreInfoButton>
          </DataPointContact>
        )}
      </Card>
    </div>
  );
}

const Card = styled.div`
  background: transparent;
  padding: 18px 24px 18px 24px;
  width: 100%;
  border-bottom: 1px solid rgba(211, 211, 211, 0.5);
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: rgb(6, 15, 25);
  padding-bottom: 8px;
`;

const Street = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 2px 0 2px 0;
  color: rgba(6, 15, 25, 0.6);
`;

const DataPoint = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: rgba(6, 15, 25, 0.6);
`;

const DataPointContact = styled.div`
  padding: 0;
  margin-left: 0;
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 40%;
`;

const ContactButton = styled.a<ContactButtonProps>`
  background: #fff;
  border: 2px solid
    ${(props) => (props.disabled ? "gray" : "rgb(24, 122, 220)")};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  cursor: pointer;
  padding: 3px 8px 3px 8px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (props.disabled ? "gray" : "rgb(24, 122, 220)")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 33%;
  font-family: Barlow Semi Condensed;
  margin-right: 8px;

  &:hover {
    background-color: rgb(24, 122, 220);
    color: #fff;
  }
`;

const MoreInfoButton = styled.div`
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: rgb(24, 122, 220);
  display: flex;
  align-items: center;
  justify-content: end;
  width: 33%;
  font-family: Barlow Semi Condensed;

  &:hover {
    text-decoration: underline;
  }
`;
