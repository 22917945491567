import maplibregl from "maplibre-gl";
import { useEffect } from "react";

interface CompanyMarkerProps {
  map: maplibregl.Map | null;
  searchedCompanies: string[];
  setCompanyInformation: any;
  setCompanyInformationSmall: any;
  searchText: string;
}

function setFilter(
  map: maplibregl.Map,
  searchedCompanies: string[],
  searchText: string
) {
  if (!searchedCompanies || searchedCompanies.length === 0) {
    map.setFilter("bus-companies-active", ["!", true]);
    map.setFilter("bus-companies-inactive", null);
    return;
  }

  if (!searchText || searchText === "") {
    if (searchedCompanies.length !== 1) {
      map.setFilter("bus-companies-active", ["!", true]);
      map.setFilter("bus-companies-inactive", null);
      return;
    }
  }

  map.setFilter("bus-companies-active", [
    "match",
    ["get", "name"],
    searchedCompanies,
    true,
    false,
  ]);

  map.setFilter("bus-companies-inactive", [
    "match",
    ["get", "name"],
    searchedCompanies,
    false,
    true,
  ]);
}

export function CompanyMarkers({
  map,
  searchedCompanies,
  searchText,
}: CompanyMarkerProps) {
  useEffect(() => {
    if (!map) {
      return;
    }

    const activeLayer = map.getLayer("bus-companies-active");

    if (!activeLayer) {
      map.addLayer({
        id: "bus-companies-active",
        type: "symbol",
        source: "bus-companies",
        layout: {
          "icon-image": [
            "match",
            ["to-number", ["get", "premium"], 0],
            1,
            "marker-premium-selected",
            "marker-selected",
          ],
          "icon-size": 0.5,
          "icon-allow-overlap": true,
          "symbol-sort-key": ["to-number", ["get", "premium"], 0],
        },
      });
    }

    const inactiveLayer = map.getLayer("bus-companies-inactive");

    if (!inactiveLayer) {
      map.addLayer(
        {
          id: "bus-companies-inactive",
          type: "symbol",
          source: "bus-companies",
          layout: {
            "icon-image": [
              "match",
              ["to-number", ["get", "premium"], 0],
              1,
              "marker-premium-not-selected",
              "marker-not-selected",
            ],
            "icon-size": 0.5,
            "icon-allow-overlap": true,
            "symbol-sort-key": ["to-number", ["get", "premium"], 0],
          },
        },
        "bus-companies-active"
      );
    }
  }, [map]);

  useEffect(() => {
    if (!map) return;
    setFilter(map, searchedCompanies, searchText);
  }, [map, searchedCompanies, searchText]);

  return <></>;
}
