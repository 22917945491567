import { createGlobalStyle } from "styled-components";
import "./App.css";
import { MapLibre } from "./components/Map/MapLibre";
import { LivemapUI, DEFAULT_DAY_THEME } from "livemap-ui";

export interface AppProps {
  search?: string;
}

function App({ search }: AppProps) {
  let dayTheme = DEFAULT_DAY_THEME;
  dayTheme.detailColor = "#e75f88";

  return (
    <LivemapUI nightTheme={dayTheme}>
      <div className="App">
        <GlobalStyles />

        <MapLibre initialSearch={search} />
      </div>
    </LivemapUI>
  );
}

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Barlow;
  }

  a {
    color: rgb(24, 122, 220);
    text-decoration: none;
  }
  
  *::-webkit-scrollbar {
    width: 8px
  }

  *::-webkit-scrollbar-track {
    background: transparent
  }

  *::-webkit-scrollbar-thumb {
    background-color: gray
  }

  .card:hover {
    cursor: pointer;
    background:	rgb(211, 211, 211, .2);
  }
`;

export default App;
