import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import styled from "livemap-ui";

export function CloseButton(props: { closeSidePanel: () => void }) {
  return (
    <Button onClick={() => props.closeSidePanel()}>
      <Icon path={mdiClose} size={1} color="gray"></Icon>
    </Button>
  );
}

const Button = styled.div`
  cursor: pointer;
`;
