import { useState, useEffect, useCallback } from "react";
import maplibregl, { GeoJSONSource } from "maplibre-gl";
import { CompanyInformationPremiumCard } from "../CompanyInformationPremiumCard";
import { CompanyMarkers } from "../CompanyMarkers";
import { About } from "../About";
import { CompanyInformationCondensedCard } from "../CompanyInformationCondensedCard";
import { CompanyInformationCard } from "../CompanyInformationCard";
import { clearSearchParams, setSearchParams } from "../../core/url";
import styled, { ZoomButtons, CompassButton } from "livemap-ui";
import {
  fitCompaniesInView,
  fitCompanyInView,
} from "../../core/fit-reports-in-view";
import { SidePanelButton } from "../SidePanelButton";
import { getCompanyInformation } from "../../hooks/company-information";
import { CloseButton } from "../CloseButton";
import { Search } from "../Search";
import { Banner } from "../Banner";

export interface MaplibreDispatch {
  onReady?(map: maplibregl.Map): void;
  initialSearch?: string;
}

type Props = MaplibreDispatch;

export function MapLibre({ initialSearch }: Props) {
  const [companyInformation, setCompanyInformation]: any = useState();
  const [companyInformationSmall, setCompanyInformationSmall]: any = useState();
  const [showAbout, setShowAbout] = useState(false);
  const [searchText, setSearchText] = useState(initialSearch ?? "");
  const [map, setMap] = useState<maplibregl.Map | null>(null);
  const [searchedCompanies, setSearchedCompanies]: any = useState();
  const [hideSidePanel, setHideSidePanel] = useState(false);
  const [data, setData]: any = useState([]);
  const [marginLeft, setMarginLeft] = useState(0);
  const [foundCompanies, setFoundCompanies]: any = useState();

  useEffect(() => {
    getCompanyInformation().then((result) => {
      setData(result);
    });
  }, []);

  useEffect(() => {
    if (hideSidePanel) {
      setMarginLeft(-400);
    } else {
      setMarginLeft(0);
    }
  }, [hideSidePanel]);

  useEffect(() => {
    if (initialSearch) {
      setSearchedCompanies(initialSearch);
    }
  }, [initialSearch]);

  const handleSearch = useCallback(
    (result: any) => {
      if (searchText === "") {
        setCompanyInformationSmall(null);
      }

      let foundCompanies = data
        .filter((item: any) =>
          item.properties.name.toLowerCase().includes(result.toLowerCase())
        )
        .map((item: any) => item.properties.name);

      setSearchedCompanies(foundCompanies);

      setSearchText(result);
    },
    [data, searchText]
  );

  const closeSidePanel = useCallback(() => {
    setCompanyInformationSmall(null);
    setCompanyInformation(null);
    clearSearchParams();
    setShowAbout(false);
    handleSearch("");
    setSearchText("");
    setSearchedCompanies(null);
    setHideSidePanel(false);
  }, [handleSearch]);

  const setupMap = useCallback(
    (coordinates: any, zoom: any) => {
      const initMap = new maplibregl.Map({
        container: "map",
        style: "https://tile.livemap24.com/styles/style-light-vector.json",
        center: coordinates[0],
        zoom: zoom,
      });

      initMap.on("load", function () {
        initMap.loadImage(
          "./images/marker-not-selected.png",
          function (error: any, image: any) {
            if (error) throw error;
            initMap.addImage("marker-not-selected", image);
          }
        );

        initMap.addSource("bus-companies", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });

        initMap.loadImage(
          "./images/marker-selected.png",
          function (error: any, image: any) {
            if (error) throw error;
            initMap.addImage("marker-selected", image);
          }
        );

        initMap.loadImage(
          "./images/marker-premium-not-selected.png",
          function (error: any, image: any) {
            if (error) throw error;
            initMap.addImage("marker-premium-not-selected", image);
          }
        );

        initMap.loadImage(
          "./images/marker-premium-selected.png",
          function (error: any, image: any) {
            if (error) throw error;
            initMap.addImage("marker-premium-selected", image);
          }
        );

        initMap.on("click", function (e: any) {
          closeSidePanel();

          var features = initMap.queryRenderedFeatures(e.point, {
            layers: ["bus-companies-active", "bus-companies-inactive"],
          });

          if (!features.length) {
            setCompanyInformation(null);
          }
        });

        initMap.on("click", "bus-companies-active", function (e: any) {
          let clickedMarker: any = [];
          clickedMarker.push(e.features[0].properties.name);
          setSearchedCompanies(clickedMarker);

          let companyInformation = e.features[0];
          let coordinates = e.features[0].geometry.coordinates;

          handleLayerClick(initMap, companyInformation, coordinates);
        });

        initMap.on("click", "bus-companies-inactive", function (e: any) {
          let clickedMarker: any = [];
          clickedMarker.push(e.features[0].properties.name);
          setSearchedCompanies(clickedMarker);

          let companyInformation = e.features[0];
          let coordinates = e.features[0].geometry.coordinates;

          handleLayerClick(initMap, companyInformation, coordinates);
        });

        initMap.on("mouseenter", "bus-companies", function () {
          initMap.getCanvas().style.cursor = "pointer";
        });

        initMap.on("mouseleave", "bus-companies", function () {
          initMap.getCanvas().style.cursor = "";
        });

        setMap(initMap);
      });
    },
    [closeSidePanel]
  );

  useEffect(() => {
    if (map) return;
    if (data.length === 0) return;

    if (initialSearch) {
      let coordinates = data
        .filter((item: any) =>
          item.properties.name
            .toLowerCase()
            .includes(initialSearch.toLowerCase())
        )
        .map((item: any) => item.geometry.coordinates);

      setupMap(coordinates, 12);
    } else {
      setupMap([[17.3071024, 62.3907552]], 4.5);
    }
  }, [map, setupMap, data, initialSearch]);

  useEffect(() => {
    if (!map) return;

    const source = map.getSource("bus-companies") as GeoJSONSource;
    source.setData({
      type: "FeatureCollection",
      features: data,
    });
  }, [map, data]);

  const handleLayerClick = (
    map: any,
    companyInformation: any,
    coordinates: any
  ) => {
    setHideSidePanel(false);
    setCompanyInformation(companyInformation);
    setCompanyInformationSmall(null);
    setSearchParams("search", [companyInformation.name]);

    map.flyTo({
      center: coordinates,
      zoom: 12,
    });
  };

  const toggleHideSidePanel = useCallback(() => {
    setHideSidePanel(!hideSidePanel);
  }, [hideSidePanel]);

  const showMenu = () => {
    setShowAbout(true);
  };

  const handleEnter = useCallback(() => {
    if (!map) return;

    if (foundCompanies && foundCompanies.length > 1) {
      fitCompaniesInView(map, foundCompanies);
    } else if (foundCompanies && foundCompanies.length === 1) {
      fitCompanyInView(map, foundCompanies[0]);
    }
  }, [map, foundCompanies]);

  useEffect(() => {
    setCompanyInformation(null);

    if (!searchText || searchText === "") {
      setCompanyInformationSmall(null);
      clearSearchParams();
    } else {
      if (!map) return;
      setShowAbout(false);

      setTimeout(() => {
        let filtered = data.filter((item: any) => {
          return item.properties.name
            .toLowerCase()
            .includes(searchText.toLowerCase());
        });

        setFoundCompanies(filtered);

        if (filtered.length > 1) {
          filtered.sort((first: any, second: any) =>
            first.properties.name
              .toLowerCase()
              .localeCompare(second.properties.name.toLowerCase(), "sv", {
                sensitivity: "base",
              })
          );

          filtered.sort(function (a: any, b: any) {
            return b.properties.premium === true
              ? 1
              : a.properties.premium === true
              ? -1
              : 0;
          });

          const multiViewCompanies = filtered.map((item: any) => (
            <CompanyInformationCondensedCard
              key={item.properties.name}
              companyInformation={item}
              map={map}
              setCompanyInformation={setCompanyInformation}
              setCompanyInformationSmall={setCompanyInformationSmall}
              setSearchedCompanies={setSearchedCompanies}
            />
          ));

          setCompanyInformationSmall(multiViewCompanies);
        } else if (filtered.length === 0) {
          setCompanyInformationSmall(null);
        } else if (filtered.length === 1) {
          setCompanyInformation(filtered[0]);
          setCompanyInformationSmall(null);
          setSearchParams("search", [filtered[0].properties.name]);
        }
      }, 100);
    }
  }, [map, searchText, data]);

  return (
    <>
      <Banner>
        <Search
          handleSearch={handleSearch}
          search={searchText}
          handleEnter={handleEnter}
          showMenu={showMenu}
          closeSidePanel={closeSidePanel}
        />
      </Banner>

      <Map id="map">
        <CompanyMarkers
          map={map}
          searchedCompanies={searchedCompanies}
          setCompanyInformation={setCompanyInformation}
          setCompanyInformationSmall={setCompanyInformationSmall}
          searchText={searchText}
        />

        {map && (
          <LowerRight>
            <MapControls>
              <CompassButton map={map}></CompassButton>
              <ZoomButtons map={map}></ZoomButtons>
            </MapControls>
          </LowerRight>
        )}
      </Map>

      {(searchText || showAbout || companyInformation) && (
        <Icons>
          <CloseButton closeSidePanel={closeSidePanel} />
        </Icons>
      )}

      {(companyInformation || companyInformationSmall || showAbout) && (
        <>
          <SidePanelButton
            onClick={toggleHideSidePanel}
            hideSidePanel={hideSidePanel}
          />

          {!hideSidePanel && (
            <>
              <SidePanel
                className="sidebar"
                style={{ marginLeft: marginLeft + "px" }}
              >
                {showAbout && <About />}

                {companyInformation && map && (
                  <>
                    {companyInformation.properties.premium === true && (
                      <CompanyInformationPremiumCard
                        companyInformation={companyInformation.properties}
                        coordinates={companyInformation.geometry.coordinates}
                        map={map}
                      />
                    )}
                    {companyInformation.properties.premium === false && (
                      <CompanyInformationCard
                        companyInformation={companyInformation.properties}
                        coordinates={companyInformation.geometry.coordinates}
                        map={map}
                      />
                    )}
                  </>
                )}

                {companyInformationSmall && (
                  <CardBox>{companyInformationSmall}</CardBox>
                )}
              </SidePanel>
            </>
          )}
        </>
      )}
    </>
  );
}

const Map = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const CardBox = styled.div`
  max-height: 88vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const SidePanel = styled.div`
  background: #fff;
  position: absolute;
  width: 550px;
  left: 0;
  top: 97px;
  height: 100%;
  overflow: hidden;
  transition: all 0.1s;
  padding-bottom: 100px;

  @media (max-width: 500px) {
    width: 100vw;
    margin: none;
  }
`;

const Icons = styled.div`
  position: absolute;
  top: 36px;
  left: 480px;
  display: flex;
  justify-content: center;
  z-index: 578437593475893479;

  @media (max-width: 900px) {
    top: 22px;
    left: calc(100vw - 54px);
  }
`;

const MapControls = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const LowerRight = styled.div`
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  right: 0;
  margin: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 12px;
`;
