import styled from "styled-components";

interface ContactButtonProps {
  href: string;
}

export function ContactButton({ href }: ContactButtonProps) {
  return (
    <Button href={href} target="_blank">
      KONTAKTA OSS
    </Button>
  );
}

const Button = styled.a<ContactButtonProps>`
  background: #fff;
  border: 2px solid rgb(24, 122, 220);
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  padding: 5px 12px 5px 12px;
  font-size: 16px;
  font-weight: 700;
  color: rgb(24, 122, 220);
  font-family: Barlow Semi Condensed;
  transition: all 0.3s;

  &:hover {
    background-color: rgb(24, 122, 220);
    color: #fff;
  }
`;
