import styled from "livemap-ui";
import Icon from "@mdi/react";
import {
  mdiHomeCircleOutline,
  mdiMapMarker,
  mdiDomain,
  mdiEarth,
  mdiEmail,
  mdiCheckBold,
  mdiCarConnected,
  mdiMapMarkerPath,
} from "@mdi/js";

import { fitCompanyInView } from "../core/fit-reports-in-view";

interface CompanyInformationPremiumProps {
  map: maplibregl.Map;
  companyInformation: any;
  coordinates: Number[];
}

interface ContactButtonProps {
  disabled: boolean;
}

export function CompanyInformationPremiumCard({
  map,
  companyInformation,
  coordinates,
}: CompanyInformationPremiumProps) {
  return (
    <Container>
      <ImageContainer>
        <Image
          src={`data/images/${companyInformation.companyRegistrationNumber}.png`}
        />
        <Logo
          src={`data/logos/${companyInformation.companyRegistrationNumber}.png`}
        />
      </ImageContainer>
      <Padding>
        <DataPoint>
          <Name>
            {companyInformation.name}
            <Icons>
              <Locate onClick={() => fitCompanyInView(map, coordinates)}>
                Visa på karta
              </Locate>
              <Icon path={mdiHomeCircleOutline} size={1.2} color="gold" />
            </Icons>
          </Name>
        </DataPoint>

        {companyInformation.companyDescription && (
          <DataPoint>{companyInformation.companyDescription}</DataPoint>
        )}
        <DataPoint>
          <Icon path={mdiMapMarker} size="22px" color="rgb(24, 122, 220)" />

          <Text>{`${companyInformation.street}, ${companyInformation.postCode}, ${companyInformation.city}`}</Text>
        </DataPoint>
        <DataPoint>
          <Icon path={mdiDomain} size="22px" color="rgb(24, 122, 220)" />
          <Text>Org.nr {companyInformation.companyRegistrationNumber}</Text>
        </DataPoint>
        {companyInformation.hasUplinkedVehicles && (
          <DataPoint>
            <Icon
              path={mdiCarConnected}
              size="22px"
              color="rgb(24, 122, 220)"
            />
            <Text>
              Ansluten till{" "}
              <HighwayCloud
                href="https://www.veridict.com/highway-cloud"
                target="_blank"
              >
                Highway Cloud
              </HighwayCloud>
            </Text>
          </DataPoint>
        )}

        {companyInformation.activeRegions && (
          <DataPoint>
            <IconContainer>
              <Icon
                path={mdiMapMarkerPath}
                size="22px"
                color="rgb(24, 122, 220)"
              />
            </IconContainer>

            <Text>Verksamma regioner: {companyInformation.activeRegions}</Text>
          </DataPoint>
        )}

        {companyInformation.typeOfBusiness && (
          <DataPoint>
            <Icon path={mdiCheckBold} size="22px" color="green" />
            <Text>{companyInformation.typeOfBusiness}</Text>
          </DataPoint>
        )}

        <DataPointContact>
          <ContactButton
            href={companyInformation.site}
            target="_blank"
            disabled={!companyInformation.site}
          >
            Hemsida <Icon path={mdiEarth} size="16px" />
          </ContactButton>

          <ContactButton
            href={`mailto:${companyInformation.email}`}
            target="_blank"
            disabled={!companyInformation.email}
          >
            Email <Icon path={mdiEmail} size="16px" />
          </ContactButton>
        </DataPointContact>
      </Padding>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 0;
  overflow-y: auto;

  @media (max-width: 500px) {
    width: 100vw;
  }

  &:hover {
    background: rgb(248, 244, 244);
  }
`;

const Padding = styled.div`
  padding: 24px;
`;

const Name = styled.div`
  font-size: 22px;
  padding: 0 0 12px 0;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: rgb(6, 15, 25);
`;

const DataPoint = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 0;
  font-weight: 500;
  color: rgba(6, 15, 25, 0.6);
`;

const Text = styled.div`
  padding-left: 8px;
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
`;

const ContactButton = styled.a<ContactButtonProps>`
  background: #fff;
  border: 2px solid
    ${(props) => (props.disabled ? "gray" : "rgb(24, 122, 220)")};
  border-radius: 30px;
  cursor: pointer;
  padding: 5px 12px 5px 12px;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => (props.disabled ? "gray" : "rgb(24, 122, 220)")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 40%;
  font-family: Barlow Semi Condensed;
  margin-left: 8px;

  &:hover {
    background-color: rgb(24, 122, 220);
    color: #fff;
  }
`;

const DataPointContact = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 24px 0 12px 0;
`;

const HighwayCloud = styled.a`
  text-decoration: underline;
  color: rgb(24, 122, 220);
  font-weight: bold;
`;

const IconContainer = styled.div`
  width: 22px;

  * {
    min-width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  height: auto;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Logo = styled.img`
  width: 40%;
  position: absolute;
  top: 12px;
  left: 12px;
`;

const Locate = styled.div`
  background: transparent;
  border: none;
  font-size: 16px;
  padding-right: 8px;
  cursor: pointer;
  color: rgb(24, 122, 220);
  font-weight: 500;
  transition: 0.2s;
  min-width: 100px;

  &:hover {
    font-weight: bold;
  }
`;
