import styled from "livemap-ui";
import { ContactButton } from "./ContactButton";

export function About() {
  return (
    <Container>
      <p>Busskartan.com © Veridict AB</p>

      <p>Datakällor: Veridict, Transportföretagen, Bisnode, Bussbolagen, OSM</p>

      <a href="https://www.veridict.com" target="_blank" rel="noreferrer">
        www.veridict.com
      </a>

      <br />

      <ContactButton href="https://www.veridict.com/busskartan" />
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 12px 24px 12px 24px;

  @media (max-width: 500px) {
    width: 100vw;
  }

  * {
    margin-bottom: 22px;
  }

  a:last-child {
    margin-top: 20px;
  }
`;
