import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import styled from "livemap-ui";

interface SidePanelButtonProps {
  opened: boolean;
}

export function SidePanelButton(props: {
  onClick: () => void;
  hideSidePanel: boolean;
}) {
  return (
    <>
      <Button opened={!props.hideSidePanel} onClick={() => props.onClick()}>
        {props.hideSidePanel && (
          <Icon
            path={mdiChevronRight}
            size={1}
            color="gray"
            title="Öppna sidopanel"
          ></Icon>
        )}
        {!props.hideSidePanel && (
          <Icon
            path={mdiChevronLeft}
            size={1}
            color="gray"
            title="Stäng sidopanel"
          ></Icon>
        )}
      </Button>
    </>
  );
}

const Button = styled.div<SidePanelButtonProps>`
  background: #fff;
  border: 1px solid lightgray;
  position: absolute;
  left: 0px;
  left: ${(props) => (props.opened ? "550px" : "0px")};
  top: calc(50vh - 20px);
  height: 40px;
  width: 20px;
  z-index: 57895734;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 500px) {
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }
`;
