import styled from "styled-components";
import { ContactButton } from "./ContactButton";

interface BannerProps {
  children: JSX.Element;
}

export function Banner({ children }: BannerProps) {
  return (
    <BannerContainer>
      <BannerContent>
        <Logo src="images/Busskartan_Logo_Vector.svg" alt="Logo"></Logo>
        {children}
      </BannerContent>

      <BannerContent>
        <Text href="https://www.veridict.com/busskartan" target="_blank">
          På jakt efter ett bra bussbolag? Vi hjälper er gärna!
        </Text>

        <ContactButtonDisplay>
          <ContactButton href="https://www.veridict.com/busskartan" />
        </ContactButtonDisplay>
      </BannerContent>
    </BannerContainer>
  );
}

const BannerContainer = styled.div`
  background-color: #fff;
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  z-index: 3;
  padding: 24px 36px 24px 36px;
  align-items: center;

  @media (max-width: 900px) {
    padding: 12px;
    display: flex;
    justify-content: normal;
    align-items: center;
    flex-direction: column;
  }
`;

const BannerContent = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 44px;

  @media (max-width: 900px) {
    width: 36px;
  }
`;

const Text = styled.a`
  color: rgb(6, 15, 25);
  font-size: 18px;
  font-weight: 500;
  margin-right: 20px;
  cursor: auto;

  @media (max-width: 1100px) {
    text-decoration: underline;
    margin-right: 0px;
    cursor: pointer;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    padding-top: 8px;
  }
`;

const ContactButtonDisplay = styled.div`
  display: inline-block;

  @media (max-width: 1100px) {
    display: none;
  }
`;
