import maplibregl, { LngLatBounds } from "maplibre-gl";

export function fitCompaniesInView(map: maplibregl.Map, companies: any[]) {
  let mappedCompanies: any = companies.map((company: any) => {
    return company.geometry.coordinates;
  });

  const bounds: any = mappedCompanies.reduce(
    (b: any, r: any) => b.extend([r[0], r[1]]),
    new LngLatBounds()
  );

  const padding: maplibregl.PaddingOptions = {
    left: 48,
    right: 48,
    top: 48,
    bottom: 48,
  };

  map.fitBounds(bounds, { maxZoom: 16, padding });
}

export function fitCompanyInView(map: maplibregl.Map, coordinates: any) {
  map.flyTo({
    center: coordinates,
    zoom: 12,
  });
}
