const url: string = "./data/bus-companies.json";

export function getCompanyInformation() {
  return fetch(url)
    .then((response) => response.json())
    .then((json) => {
      let data = json.features;
      if (!data) {
        throw Error("no data");
      }

      return data;
    });
}
