import React from "react";
import styled from "livemap-ui";
import { setSearchParams } from "../core/url";
import { mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";

export interface SearchProps {
  handleSearch: (e: string) => void;
  search: string;
  handleEnter: () => void;
  showMenu: () => void;
  closeSidePanel: () => void;
}

export function Search({
  handleSearch,
  search,
  handleEnter,
  showMenu,
}: SearchProps) {
  React.useEffect(() => {
    window.addEventListener("keyup", (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleEnter();
      }
    });

    return () => {
      window.removeEventListener("keyup", handleEnter);
    };
  }, [handleEnter]);

  const handleInput = (e: string) => {
    handleSearch(e);
    setSearchParams("search", [e]);
  };

  const handleMenuClick = () => {
    showMenu();
  };

  return (
    <SearchContainer>
      <MenuIcon onClick={() => handleMenuClick()}>
        <Icon path={mdiMenu} color="gray" size={1} />
      </MenuIcon>

      <SearchBar
        className="search"
        onChange={(e) => handleInput(e.target.value)}
        value={search}
        placeholder="Sök i busskartan..."
      ></SearchBar>
    </SearchContainer>
  );
}

const SearchContainer = styled.div`
  background: rgb(244, 244, 244);
  border: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  padding: 10px 12px 10px 20px;
  margin-left: 36px;
  text-align: left;

  @media (max-width: 900px) {
    width: calc(100vw - 80px);
    margin-left: 12px;
    padding: 8px 10px 8px 10px;
  }
`;

const SearchBar = styled.input.attrs({
  placeholderTextColor: "#fff",
})`
  background: transparent;
  border: none;
  color: gray;
  font-size: 16px;
  width: 100%;
  height: 100%;
  outline: none;
  padding-left: 12px;

  @media (max-width: 900px) {
    font-size: 14px;
    width: calc(100vw - 24px);
  }
`;

const MenuIcon = styled.div`
  cursor: pointer;
`;
