export function findSearchParams(paramName: string): string[] {
  const searchParams = window.location.search
    .substring(1)
    .split("&")
    .map((part) => part.split("=", 2) as [string] | [string, string]);
  const values: string[] = [];
  for (const [name, value] of searchParams) {
    if (paramName === name && value !== undefined) {
      values.push(decodeURIComponent(value));
    }
  }
  return values;
}

export function clearSearchParams() {
  window.history.replaceState(
    {},
    document.title,
    window.location.href.replace(window.location.search, "")
  );
}

export function setSearchParams(paramName: string, values: string[]) {
  if (values.length === 0 || !values[0]) {
    window.history.replaceState(null, "", "?");
    return;
  }

  const search = `?${paramName}=${encodeURIComponent(
    values.join(`&${paramName}=`)
  )}`;
  window.history.replaceState(null, "", search);
}
